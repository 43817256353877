import { defineStore } from 'pinia';

export const useLevelStore = defineStore('level', {
  state: () => ({
    levels: [],
    loading: false,
  }),
  actions: {
    async fetchLevels(force = false) {
      const api = useApi();

      if ((this.levels.length <= 0 && !this.loading) || force) {
        this.loading = true;
        const response = await api.execute('/api/levels');
        this.levels = response;
        this.loading = false;
      }
    },
  },
});
